import 'vuetify/dist/vuetify.min.css';
import "./index.scss";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import VueRecaptcha from 'vue-recaptcha';

Vue.use(Vuetify);


(function () {
    "use strict";
    window.app = new Vue({
        vuetify: new Vuetify({
            icons: {
                iconfont: 'mdi'
            }
        }),
        delimiters : ['${','}'],

        el: '#app',
        components : {
            VueRecaptcha
        },
        data : ()=> ({

           errors: '',
            snackBar: false,
            snackMessage: '',
            email: "",
            password: ""
        }),
        mounted(){

        },

        methods: {
            showSnack(message) {

                this.snackMessage = message;
                this.snackBar = true;
            },
            submit() {
                loginForm.submit();
            }
        }

    })
})();


